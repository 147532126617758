







































































































.help {
  width: 100%;
  max-width: 1368px;
  position: relative;
  padding: 1.25rem;
  margin: 0 auto;
  @media (max-width: 480px) {
    padding: 5px;
  }
  h4 {
    text-align: center;
    color: #fff;
  }
  .help-item {
    background-image: linear-gradient(
      to right,
      rgba(105, 179, 1, 0.4),
      rgba(85, 89, 102, 0.4)
    );
    display: flex;
    width: 100%;
    max-width: 500px;
    margin: 15px auto 0px;
    padding: 0px 2rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 1.25rem;
    @media (max-width: 480px) {
      padding: 5px 1rem;
      height: auto;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 20px;
    }
    &:hover {
      border: 1px solid #7bc514;
    }
    .slot {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      color: rgb(255, 255, 255);
      cursor: pointer;
    }
  }
}
.modal-help {
  .modal-dialog {
    @media (max-width: 575px) {
      margin: 0px;
      padding: 0.5em;
    }
    .modal-body {
      height: 500px;
      padding: 0px;
      margin-top: 0px;
      .youtube-section {
        height: 100%;
        position: relative;
        z-index: 1;
        .btn-close {
          position: absolute;
          top: 0px;
          right: 0px;
          color: red;
          background-color: #ffffff;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-content: center;
          line-height: 30px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
